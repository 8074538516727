
import { Vue, Component, Ref } from 'vue-property-decorator'
import { RuleDetail, TimeList } from '@/types/onlineStatistics'
import { Page } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component
export default class RuleSettings extends Vue {
  @Ref('form') readonly elForm!: ElForm

  page = 1
  size = 10
  total = 0
  loading = false
  tableData: RuleDetail[] = []
  currentData: RuleDetail = {
    projectId: '',
    timeH: '',
    timeM: '',
    ctime: '',
    timeList: [],
    isDel: 0,
    timeRange: []
  }

  rules = {}

  timeRange: string[][] = []

  dialogVisible = false

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get<Page<RuleDetail>>(this.$apis.recruitUse.selectYhWorkerRulesByPage, {
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    })
  }

  onEdit (row: RuleDetail) {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRulesByProjectId, {
      projectId: row.projectId
    }).then(res => {
      const currentData = JSON.parse(JSON.stringify(row)) as RuleDetail
      const range: string[][] = []
      if (res.timeList && res.timeList.length > 0) {
        res.timeList.forEach((item: TimeList) => {
          range.push([
            (item.startTime || ''), (item.endTime || '')
          ])
        })
      }
      currentData.timeRange = range
      this.currentData = currentData
      this.timeRange = range
      this.dialogVisible = true
    })
  }

  addTime () {
    this.timeRange.push(['', '23:00'])
  }

  delTime (index: number) {
    this.timeRange.splice(index, 1)
  }

  save () {
    const currentData = JSON.parse(JSON.stringify(this.currentData)) as RuleDetail
    if (this.timeRange && this.timeRange.length > 0) {
      currentData.timeList = this.timeRange.map(item => {
        return {
          startTime: item[0],
          endTime: item[1]
        }
      })
    } else {
      currentData.timeList = []
    }
    delete currentData.timeRange
    this.elForm.validate().then(() => {
      this.$axios.post(this.$apis.recruitUse.updateYhWorkerRules, currentData).then(() => {
        this.dialogVisible = false
        this.$message.success('保存成功')
        this.loadData()
      })
    })
  }

  cancel () {
    this.dialogVisible = false
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
